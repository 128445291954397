import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "../src/assets/style/main.scss"
import VueCookies from 'vue-cookies';
import VeeValidate from 'vee-validate';
import VueGtag from "vue-gtag";
import AppEventBus from './appEventBus';


//FILES
import "./assets/ImpactHQPrivacyPolicy.pdf";



import TrainingService from './services/trainingService';
import IndustryNewsService from './services/industryNewsService';
import GrantsService from './services/grantsService';
import LeadershipProgramService from './services/leadershipProgramService';
import FunderService from './services/funderService';
import GrantFormService from './services/grantFormService';
import UserService from './services/userService';
import OrganizationService from './services/organizationService';
import newImpactService from './services/newImpactService';
import contactService from './services/contactService';
import applicationService from './services/applicationService';
import contentResourceService from './services/contentResourceService';
import singleTypeService from './services/singleTypeService';
import ChoiceService from './services/choiceService'
import ResourceService from './services/resourceService'
import ResourceFormService from './services/resourceFormService'

import axios from 'axios';
import VueAxios from 'vue-axios';
import Pagination from 'vue-pagination-2';

Vue.config.productionTip = false

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(TrainingService);
Vue.use(IndustryNewsService);
Vue.use(GrantsService);
Vue.use(LeadershipProgramService);
Vue.use(FunderService);
Vue.use(GrantFormService);
Vue.use(UserService);
Vue.use(OrganizationService);
Vue.use(newImpactService);
Vue.use(contactService);
Vue.use(applicationService);
Vue.use(contentResourceService);
Vue.use(singleTypeService);
Vue.use(ChoiceService);
Vue.use(ResourceService);
Vue.use(ResourceFormService);
Vue.use(VeeValidate, {
  events: '',
});
Vue.use(VueGtag, {
  config: { id: 'G-26SR98W329' }
}, router);
Vue.use(AppEventBus);
Vue.component('pagination', Pagination);

VeeValidate.Validator.extend('verify_password', {
  getMessage: () => 'The password must be a minimum of 8 characters long contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ! etc)',
  validate: value => {
    let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,])(?=.{8,})');
    return strongRegex.test(value);
  }
});

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  return formatter.format(value);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
